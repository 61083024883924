var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"flashcardHeader d-flex"},[_c('div',{staticClass:"pl-3 return"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],staticStyle:{"color":"#42a16a"},attrs:{"to":{ name: 'Home' }}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}],staticStyle:{"color":"#42a16a"},on:{"click":function($event){_vm.edit = false}}},[_c('i',{staticClass:"fas fa-chevron-left"})])],1),_vm._m(0)]),_c('div',{staticClass:"container content"},[_c('div',{staticClass:"row"},[_c('el-divider',[_c('span',{staticStyle:{"font-size":"20px"}},[_c('i',{staticClass:"fas fa-book book"})])]),_vm._l((_vm.books),function(book,index){return _c('router-link',{key:index,staticClass:"col-12 content-item",attrs:{"to":{
          name: 'FlashCards',
          query: {
            index:index
          }
        }}},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"list-title"},[_vm._v(_vm._s(book.title))])])])}),_c('el-divider',[_c('span',{staticStyle:{"font-size":"20px"}},[_c('i',{staticClass:"fas fa-star starIsSelected"})])]),_c('router-link',{staticClass:"col-12 content-item default",attrs:{"to":{
          name: 'MyVocabLists',
        }}},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"list-title"},[_vm._v(_vm._s(_vm.$t("flashCards.My Vocab Lists")))])])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headerTitle"},[_c('span',{staticClass:"pr-4"},[_vm._v("Vocabs")])])
}]

export { render, staticRenderFns }