<template>
  <div class="box">
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <router-link
          :to="{ name: 'Home' }"
          style="color: #42a16a;"
          v-show="!edit"
        >
          <i class="fas fa-chevron-left"></i>
        </router-link>
        <span style="color: #42a16a;" @click="edit = false" v-show="edit">
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>
      <div class="headerTitle">
        <span class="pr-4">Vocabs</span>
      </div>
    </div>
    <div class="container content">
      <div class="row">
        <el-divider><span style="font-size:20px"><i class="fas fa-book book"></i></span></el-divider>
        <router-link
          class="col-12 content-item"
          :to="{
            name: 'FlashCards',
            query: {
              index:index
            }
          }"
          v-for="(book,index) in books"
          :key="index"
        >
          <div class="list">
            <div class="list-title">{{ book.title}}</div>
          </div>
        </router-link>
        <el-divider><span style="font-size:20px"><i class="fas fa-star starIsSelected"></i></span></el-divider>
        <router-link
          class="col-12 content-item default"
          :to="{
            name: 'MyVocabLists',
          }"
        >
          <div class="list">
            <div class="list-title">{{ $t("flashCards.My Vocab Lists") }}</div>
          </div>
        </router-link>
        <!-- <router-link
          class="col-12 content-item default"
          :to="{
            name: 'MyWords',
          }"
        >
          <div class="list">
            <div class="list-title">
              我的生字卡
            </div>
          </div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import VocabList from "@/apis/myVocabLists";
import Books from "@/apis/flashcards";

export default {
  metaInfo() {
    return {
      title: "My Vocab List - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      edit: false,
      dialogVisible: false,
      activeList: {
        id: 0,
        title: "",
        intro: ""
      },
      books:[],
      lists: []
    };
  },
  computed: {},
  async mounted() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    // this.getLists();
    this.books = await Books.getBooks();
  },
  beforeDestroy() {
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
  },
  watch: {},
  methods: {
    async createList() {
      if (this.activeList.title !== "") {
        await VocabList.createVocabList({
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    },
    async saveList() {
      if (this.activeList.title !== "") {
        await VocabList.saveVocabList(this.activeList.id, {
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.dialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    },
    async deleteList(id, index) {
      await this.$confirm("確認刪除？").then(_ => {
        VocabList.deleteVocabList(id);
        this.lists.splice(index, 1);
        this.$notify({
          message: "已刪除",
          position: "bottom-right"
        });
      });
    },
    async getLists() {
      const res = await VocabList.getVocabLists();
      this.lists = res.vocab_collects;
    },
    showNewList() {
      this.dialogVisible = true;
      this.activeList = {
        id: 0,
        title: "",
        intro: ""
      };
    },
    showEditList(list) {
      this.activeList = list;
      this.dialogVisible = true;
    }
  }
};
</script>

<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 95%;
  }
}
</style>
<style scoped>
.box {
  height: 100vh;
  display: grid;
  grid-template-rows: 55px auto;
}
.row {
  margin: 0;
}
.content {
  overflow: auto;
  padding-left: 8px;
  padding-right: 8px;
}
.content-item {
  padding-left: 8px;
  padding-right: 8px;
}
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
}
.return:hover {
  opacity: 60%;
}
.content {
  padding-top: 15px;
}
.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #42a16a;
  border-radius: 6px;
  cursor: pointer;
  height: 140px;
  padding: 10px;
  background: #42a16a;
}
.default .list {
  background: orange;
  border: 1px solid orange;
}
.list-title {
  text-align: center;
  color: #fff;

  font-size: 20px;
  font-weight: 500;
}
.list-description {
  float: left;
  height: 90px;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  word-break: break-word !important;
}
.add-button {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  height: 140px;
}
.content-item {
  margin-bottom: 15px;
}
.add-button span {
  font-size: 28px;
  line-height: 140px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}

.wordCard {
  border-bottom: 1px solid #d7dae2;
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
}
.wordCard.addNew {
  font-size: 14px;
  font-weight: 500;
}
.wordCard .word {
  color: #42a16a;
  font-size: 16px;
  font-weight: 500;
}
.starIsSelected {
  color: orange;
  cursor: pointer;
}
.book {
  color: #42a16a;
}
.btnStyle:hover {
  opacity: 0.5;
}
</style>
